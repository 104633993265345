import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/wallet-transactions';

export default {
  getModelWalletTransactions(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  AddFunds(walletTransaction) {
    return axios.post(`${baseUrl}/add-funds`, walletTransaction);
  },
  RemoveFunds(walletTransaction) {
    return axios.post(`${baseUrl}/remove-funds`, walletTransaction);
  },

  getWalletTransactionsAdmin(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`/admin${baseUrl}${queryString}`);
  },
}
