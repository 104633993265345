<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div :class="{'disabled': loading}">
    <b-card-actions action-refresh action-collapse @refresh="getMoreWalletTransactions(1)" title="Bill Details" ref="refreshCard" class="" no-body>
      <b-card-body>
        <b-card-sub-title>These are the details and transactions related to this bill.</b-card-sub-title>
      </b-card-body>

      <div v-if="bill.enterprise" class="m-1">
        <b-row>
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-between">
              <b-card-title class="m-0">Enterprise: {{bill.enterprise.display_name}}</b-card-title>
              <b-card-title class="m-0">Date: {{bill.created_at | formatDate}}</b-card-title>
              <b-card-title class="m-0">Total Bill: {{bill.amount | intToCurrency}}</b-card-title>
            </div>
          </b-col>
        </b-row>
      </div>

      <section>
        <b-table
          class="data-table"
          hover
          :items="walletTransactions"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>

          <template #cell(amount)="data">
            {{data.item.amount | intToCurrency}}
          </template>

          <template #cell(owner)="data">
            <router-link :to="{ name: 'owner-view', params: { uid: data.item.owner.uid } }" class="font-weight-bold d-block text-nowrap">
              {{ data.item.owner.identifier}}
            </router-link>
          </template>

          <template #cell(user)="data">
            <router-link :to="{ name: 'user-view', params: { uid: data.item.user.uid } }" class="font-weight-bold d-block text-nowrap">
              {{ data.item.user.name}}
            </router-link>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreWalletTransactions" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <p v-if="total_count === 0" class="col-6 my-1 mx-2">No records</p>
        </b-row>
      </section>
    </b-card-actions>
  </div>


</template>
<!--eslint-enable-->

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import WalletService from '@/services/WalletService';
import BillService from '@/services/BillService';

export default {
  name: 'BillDetails',
  components: {
    BCardActions,
  },
  props: {
    id: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          key: 'created_at',
          label: 'Date',
        },
        {
          key: 'amount',
          label: 'amount',
        },
        {
          key: 'owner',
          label: 'Owner',
        },
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'user',
          label: 'Created By',
        },
      ],
      bill: {},
      walletTransactions: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 15,
        bill_id: this.id,
      },
      total_count: 0,
      balance: 0,
    };
  },
  mounted() {
    this.getBill()
    this.getWalletTransactions();
  },
  methods: {
    async getBill() {
      try {
        const res = await BillService.getBill(this.id)
        this.bill = res.data
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get bill details, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    getMoreWalletTransactions(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getWalletTransactions();
    },
    async getWalletTransactions() {
      try {
        this.$refs.refreshCard.showLoading = true

        const res = await WalletService.getWalletTransactionsAdmin(this.filters)
        this.walletTransactions = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get wallet transactions, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      } finally {
        setTimeout(() => {
          this.$refs.refreshCard.showLoading = false
        }, 100)
      }
    },
    showWalletModal(modalName) {
      this.$bvModal.show(modalName);
    },
    closeModals() {
      this.$bvModal.hide('modal-add-funds');
      this.getWalletTransactions()
    },
  },
};
</script>
